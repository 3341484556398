import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./components/layout/full-layout/full-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { TranslateModule } from "@ngx-translate/core";
import { DragulaModule } from "ng2-dragula";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { GalleryModule } from "@ks89/angular-modal-gallery";

// import "hammerjs";
// import "mousetrap";

// services
import { NavService } from "../services/nav.service";
import { CustomizerService } from "../services/customizer.service";
// Directives
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HeaderWebPageComponent } from './components/header-web-page/header-web-page.component';
import { LayoutWebPageComponent } from './components/layout-web-page/layout-web-page.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FooterWebPageComponent } from './components/footer-web-page/footer-web-page.component';
import { LoaderContentComponent } from './components/loader-content/loader-content.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [LoaderComponent, HeaderComponent, FooterComponent, SidebarComponent,  ContentLayoutComponent, FullLayoutComponent, FeatherIconsComponent, BreadcrumbComponent, HeaderWebPageComponent, LayoutWebPageComponent, FilterPipe, FooterWebPageComponent, LoaderContentComponent],
  imports: [CommonModule, RouterModule, FormsModule, TranslateModule, DragulaModule.forRoot(), NgbModule, GalleryModule],
  exports: [LoaderComponent, FeatherIconsComponent, TranslateModule,FilterPipe,LoaderContentComponent],
  providers: [NavService, CustomizerService],
})
export class SharedModule {}
