import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loader-content',
  templateUrl: './loader-content.component.html',
  styleUrls: ['./loader-content.component.scss']
})
export class LoaderContentComponent {

  @ViewChild('content', { static: true }) content!: TemplateRef<any>;

}
